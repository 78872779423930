const getEisenMengerSynRisk = (total) => {
  switch (total) {
      case 1:
        return '4%, HIGH RISK'
      case 2:
        return '8%, HIGH RISK'
      case 3:
        return '16%, HIGH RISK'
    default:
        return '>25%, HIGH RISK'
  }
}

const getCyanoticNonEisenmengerRisk = (total) => {
  switch (total) {
      case 1:
        return '3%, HIGH RISK'
      case 2:
        return '7%, HIGH RISK'
      case 3:
        return '15%, HIGH RISK'
    default:
        return '>25%, HIGH RISK'
  }
}

const getEbsteinAnomalyRisk = (total) => {
  switch (total) {
      case 1:
        return '1%, LOW RISK'
      case 2:
        return '2%, LOW RISK'
      case 3:
        return '5%, HIGH RISK'
      case 4:
        return '11%, HIGH RISK'
      case 5:
        return '23%, HIGH RISK'
    default:
        return '>25%, HIGH RISK'
  }
}

const getFontanCirculationRisk = (total) => {
  switch (total) {
      case 1:
        return '<1%, LOW RISK'
      case 2:
        return '2%, LOW RISK'
      case 3:
        return '5%, HIGH RISK'
      case 4:
        return '10%, HIGH RISK'
      case 5:
        return '20%, HIGH RISK'
      case 6:
        return '>25%, HIGH RISK'
    default:
        return 'NA*'
  }
}

const getTGAMustardRisk = (total) => {
  switch (total) {
      case 1:
        return '<1%, LOW RISK'
      case 2:
        return '2%, LOW RISK'
      case 3:
        return '4%, HIGH RISK'
      case 4:
        return '8%, HIGH RISK'
      case 5:
        return '17%, HIGH RISK'
      case 6:
        return '>25%, HIGH RISK'
    default:
        return '>25%, HIGH RISK'
  }
}

const getCongenitallyCorrectTGARisk = (total) => {
  switch (total) {
      case 1:
        return '<1%, LOW RISK'
      case 2:
        return '<1%, LOW RISK'
      case 3:
        return '2%, LOW RISK'
      case 4:
        return '4%, HIGH RISK'
      case 5:
        return '9%, HIGH RISK'
      case 6:
        return '18%, HIGH RISK'
    default:
        return '>25%, HIGH RISK'
  }
}

const getLeftSidedLesionsRisk = (total) => {
  switch (total) {
      case 1:
        return '<1%, LOW RISK'
      case 2:
        return '<1%, LOW RISK'
      case 3:
        return '2%, LOW RISK'
      case 4:
        return '3%, HIGH RISK'
      case 5:
        return '7%, HIGH RISK'
      case 6:
        return '15%, HIGH RISK'
    default:
        return '>25%, HIGH RISK'
  }
}

const getTetralogyOfFallotRisk = (total) => {
  switch (total) {
      case 1:
        return '<1%, LOW RISK'
      case 2:
        return '<1%, LOW RISK'
      case 3:
        return '1%, LOW RISK'
      case 4:
        return '3%, HIGH RISK'
      case 5:
        return '6%, HIGH RISK'
      case 6:
        return '14%, HIGH RISK'
    default:
        return '>25%, HIGH RISK'
  }
}

const getClosedASDRisk = (total) => {
  switch (total) {
      case 1:
        return '<1%, LOW RISK'
      case 2:
        return '<1%, LOW RISK'
      case 3:
        return '1%, LOW RISK'
      case 4:
        return '2%, LOW RISK'
      case 5:
        return '5%, HIGH RISK'
      case 6:
        return '10%, HIGH RISK'
    default:
        return '22%, HIGH RISK'
  }
}



export const getResults = (diagnosis, total) => {
  switch (diagnosis) {
    case 1:
      return getEisenMengerSynRisk(total)
    case 2:
      return getCyanoticNonEisenmengerRisk(total)
    case 3:
      return getEbsteinAnomalyRisk(total)
    case 4:
      return getFontanCirculationRisk(total)
    case 5:
      return getTGAMustardRisk(total)
    case 6:
      return getCongenitallyCorrectTGARisk(total)
    case 7:
      return getLeftSidedLesionsRisk(total)
    case 8:
      return getTetralogyOfFallotRisk(total)
    case 9:
      return getClosedASDRisk(total)
    default:
      return getEisenMengerSynRisk(total)
  }
}