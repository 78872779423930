import {useState} from 'react';
import './App.css';
import ReactGA from 'react-ga';
import Button from '@mui/material/Button';

import { DiagnosisOptions } from './DiagnosisOptions';
import { getResults } from './getRisk'

function App() {
  const TRACKING_ID = 'G-6DWPNB9JEE';
  ReactGA.initialize(TRACKING_ID);

  const [coronaryArteryDisease, setCoronaryArteryDisease] = useState(true)
  const [heartFailureSymptoms, setHeartFailureSymptoms] = useState(true)
  const [supraventTach, setSupraventTachy] = useState(true)
  const [impairedSysVF, setImpairedSysVF] = useState(true)
  const [impairedSubpulmVF, setImpairedSubpumVF] = useState(true)
  const [QRSDuration, setQRSDuration] = useState(true)
  const [QTDispersion, setQTDispersion] = useState(true)
  const [total, setTotal] = useState(7)
  const [diagnosis, setDiagnosis] = useState(8)

  let adjustedTotal = total
  if (diagnosis === 4 && impairedSubpulmVF) {
    adjustedTotal--
  }

  let risk = getResults(diagnosis, adjustedTotal)
  if (!adjustedTotal) {
    risk = 'At least 1 SCD required to calculate risk'
  }

const list = [
  {qst: 'Coronary artery disease', value: coronaryArteryDisease, func: setCoronaryArteryDisease},
  {qst: 'Heart failure symptoms (NYHA FC II/III)', value: heartFailureSymptoms, func: setHeartFailureSymptoms},
  {qst: 'Supraventricular tachycardia', value: supraventTach, func: setSupraventTachy},
  {qst: 'Impaired systemic ventricular function (ejection fraction < 40% on echocardiography)', value: impairedSysVF, func: setImpairedSysVF},
  {qst: 'Impaired subpulmonary ventricular function (ejection fraction < 40% on echocardiography)',
    canHide: true,
    value: impairedSubpulmVF, func: setImpairedSubpumVF},
  {qst: 'QRS duration >= 120 ms', value: QRSDuration, func: setQRSDuration},
  {qst: 'QT dispersion >= 70 ms', value: QTDispersion, func: setQTDispersion}
]

  return (
    <div className="App">
      <header className="App-header">
        <h3>
          Annual Risk of SCD
        </h3>
        <h2>{risk}</h2>
      </header>
      <div style={{marginBottom: '45px'}}>
        <h1>PREVENTION-ACHD Calculator</h1>
        <DiagnosisOptions diagnosis={diagnosis} setDiagnosis={setDiagnosis} />
        {
          list.map(option => {
            const handleYes = () => {
              if (!option.value) {
                option.func(true)
                setTotal(total + 1)
              }
            }
            const handleNo = () => {
              if (option.value) {
                option.func(false)
                setTotal(total - 1)
              }
            }
            if (diagnosis === 4 && option.canHide) return null
            return (
              <div key={option.qst} style={ {display: 'flex', fontSize: '1em', justifyContent: 'center', width: '90%', alignItems: 'center', margin: '10px' }}>
                <p style={ {width: '45%', marginRight: '20px'} }>{option.qst}</p>
                <div>
                  <Button style={ {marginRight: '15px', backgroundColor: option.value ? '#8C1515' : ''} } variant={option.value ? 'contained' : 'outlined'} onClick={handleYes}>
                    YES
                  </Button>
                  <Button style={{ backgroundColor: option.value ? '' : '#8C1515'}} variant={option.value ? 'outlined' : 'contained'} onClick={handleNo}>
                    NO
                  </Button>
                </div>
              </div>
            )
          })
        }
      </div>
      <div style={{backgroundColor: '#2E2D29', color: 'white', textAlign: 'center', alignItems: 'center'}}>
        <p style={{margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          Made with 
          <span style={{fontSize: '200%', color: 'red', margin: '0 5px'}}>&hearts;</span>
          by <a href="https://twitter.com/jenwoomd" target="_blank" style={{color: '#B83A4B', margin: '0 5px'}}>@JenWooMD</a> and 
          <a href="https://twitter.com/tomatodevy" target="_blank" style={{color: '#B83A4B', margin: '0 5px'}}>@TomatoDevy</a>
        </p>
        <div style={{textAlign: 'left', padding: '25px'}}>
        <p>
          This calculator is for use by doctors in patients with adult congenital heart disease who are >= 18 years of age with > 1 major SCD risk factor. Estimating annual SCD risk identified high-risk patients who may benefit from preventive implantable cardioverter-defibrillator implantation. 
        </p>
        <p>
          The PREVENTION-ACHD risk score model had a sensitivity of 0.5 and a specificity of 0.93 for SCD or VT/VF at 2 years and a sensitivity of 0.5 and specificity of 0.92 for SCD alone. The C-statistic of the PREVENTION-ACHD risk score model predicting SCD or VT/VF was 0.75 (95% CI 0.59-0.92). When predicting death from SCD alone, the C-statistic of the risk score model was 0.81 (95% CI 0.67-0.95). 
        </p>
        <p>
          Citation: Vehmeijer JT, Koyak Z, Leerink JM, et al. Identification of patients at risk of sudden cardiac death in congenital heart disease: The Prospective study on implantable cardioverter defibrillator therapy and sudden cardiac death in Adults with Congenital Heart Disease (PREVENTION-ACHD). Heart Rhythm Society. 2021: 18(5): 785-792. 
        </p>
        </div>
      </div>
    </div>
  );
}

export default App;
