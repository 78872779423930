import {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { FormControl, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

// const useStyles = makeStyles({
//     select: {
//         '&:before': {
//           borderColor: '#8C1515',
//         },
//         '&:after': {
//           borderColor: '#8C1515',
//         },
//         '&:not(.Mui-disabled):hover::before': {
//           borderColor: '#8C1515',
//         },
//     },
//     icon: {
//       borderColor: '#8C1515',
//     },
//     root: {
//       borderColor: '#8C1515'
//     }
// })
const StanfordSelect = styled(Select)(({ theme }) => ({
  '& .MuiInputBase-input': {
    '&:before': {
      borderColor: '#8C1515',
    },
    '&:after': {
      borderColor: '#8C1515',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: '#8C1515',
    },
    '&:focus': {
      borderColor: '#8C1515',
    }
  }
}));






export const DiagnosisOptions = ({diagnosis, setDiagnosis}) => {
  const options = [
    {label: 'Eisenmenger syndrome', value: 1},
    {label: 'Cyanotic non-Eisenmenger', value: 2},
    {label: 'Ebstein anomaly', value: 3},
    {label: 'Fontan circulation', value: 4},
    {label: 'TGA Mustard/Senning repair', value: 5},
    {label: 'Congenitally corrected TGA', value: 6},
    {label: 'Left sided lesions', value: 7},
    {label: 'Tetralogy of Fallot', value: 8},
    {label: 'Closed ASD', value: 9}
  ]

  return (
    <FormControl style={{width: '80%'}}>
      <InputLabel style={{color: '#8C1515'}} id={'patient-diagnosis'}>Patient Diagnosis</InputLabel>
      <Select
        labelId="patient-diagnosis"
        label={'Patient Diagnosis'}
        value={diagnosis}
        onChange={(e) => setDiagnosis(e.target.value)}
      >
        {
          options.map(opt => {
            return (
              <MenuItem key={opt.label} value={opt.value}>{opt.label}</MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  );
}